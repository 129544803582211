
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-container>
      <b-card  title="Add outlet loyalty conversion" class="card-icon mb-30 p-16">
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <form action="#" class="form-horizontal "  method="post" v-on:submit.prevent="validateBeforeSubmitNewOutletConversion">
              <input type="hidden" name="_token" />
              <div class="form-body">
                <div class="row" >
                  <div class="col-md-4">
                    <div class="form-group ">
                      <label class="control-label "><strong>Outlet<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                      <div >
                        <select v-validate="'required'" v-model="outlet.outlet_id" class="form-control" name="merchant">
                            <option value=""> -- Select -- </option>
                            <option :value="outlet.id" v-for="outlet in outlets">{{ outlet.name }}</option>
                        </select>
                        <span v-show="errors.has('merchant')" class="help text text-danger">{{ errors.first('merchant') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="control-label "><strong>Accrual conversion:</strong></label> <br/>
                    <div class="input-group">
                      <input type="text" v-model="outlet.accrual_value" class="form-control" placeholder="Accrual value" />
                      <div class="input-group-append">
                        <span class="input-group-text" style="font-weight: 700;"> = </span>
                      </div>
                      <input type="text" v-model="outlet.loyalty_points_accrual" class="form-control" placeholder="Loyalty points" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="control-label "><strong>Redemption conversion:</strong></label> <br/>
                    <div class="input-group">
                      <input type="text" v-model="outlet.redemption_value" class="form-control" placeholder="Redemption value" />
                      <div class="input-group-append">
                        <span class="input-group-text" style="font-weight: 700;"> = </span>
                      </div>
                      <input type="text" v-model="outlet.loyalty_points_redemption" class="form-control" placeholder="Loyalty points" />
                    </div>
                  </div>
                </div>

                <div class="row" >
                  <div class="col-md-12">
                    <button class="btn btn-danger float-right" style="color:#fff;" type="submit"> Save </button>
                  </div>
                </div>

              </div>

            </form>
          </b-col>
        </b-row>

      </b-card>
    </b-container>




  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'




export default {
  data() {
    return {
      disabled:false,
      isLoading: false,
      fullPage: true,
      outlets:[],
      outlet:{
        id:0,
        outlet_id:"",
        loyalty_points_accrual:"",
        redemption_value:"",
        loyalty_points_redemption:"",
        accrual_value:"",

      }
    };
  },
  components: {
    Loading,
    DatePicker
  },
  mounted (){
    this.getOutletData();
  },
  methods: {
    ...mapActions(["saveOutletConversion","getOutlets"]),



    getOutletData(){
      let self = this;
      this.getOutlets()
        .then(function (outlets) {


          self.outlets = outlets;


        })
        .catch(function (error) {
          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },

    saveOutletConversionData(){
      this.isLoading = true;

      let self = this;


      this.saveOutletConversion(this.outlet).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Outlet conversion added.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        this.$router.push("/app/pages/settings");

        //self.getOutletData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          /*if( typeof error.response.status != "undefined"){
             if(error.response.status == 401){
               location.replace("/app/sessions/signIn");
             }

          }
          else{




          }*/
        })
    },


    validateBeforeSubmitNewOutletConversion(){
      let self = this;
      this.$validator.validate().then(valid => {
        if (valid) {
          self.saveOutletConversionData();
        }
      });

    },
  }
};
</script>
